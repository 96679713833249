<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="7"
        class="d-none d-lg-flex align-items-center auth-back p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5" />
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <img
            fluid
            src="@/assets/images/logo/redesign/PROMOSHOP LOGO latinoamerica ok.png"
            alt="Login V2"
            width="200"
            class="mb-3"
          >
          <b-card-title class="mb-1">
            ¡Bienvenido de vuelta! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Ingresa para acceder a tu cuenta y descubrir promocionales personalizados para tus clientes.
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="juan@ejemplo.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Contraseña</label>
                  <b-link :to="{name:'forgot-password'}">
                    <small>¿Olvidaste tu contraseña?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Contraseña"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                class="btn-gradient-promo"
                block
                :disabled="submitting"
              >
                <b-spinner
                  v-if="submitting"
                  small
                />
                Iniciar sesión
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>¿Eres nuevo? </span>
            <b-link
              v-if="!isCentroAmerica"
              href="mailto:soporte@promoshopclarios.com"
            >
              <span>Contacta a un agente</span>
            </b-link>
            <b-link
              v-if="isCentroAmerica"
              href="mailto:soporte@promoshopclarioscam.com"
            >
              <span>Contacta a un agente</span>
            </b-link>
          </b-card-text>

          <b-card-text class="text-center mt-1">
            <span>¿Necesitas soporte? </span>
            <a
              class="text-primary"
              @click="crispChat()"
            >
              <span>Hablar con un agente</span>
            </a>
            <span> o envía un correo a </span> <span class="text-primary" v-if="isCentroAmerica">soporte@promoshopclarioscam.com</span> <span class="text-primary" v-if="!isCentroAmerica">soporte@promoshopclarios.com</span>
          </b-card-text>

        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BButton, BForm, BFormInput, BFormGroup, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { IS_CENTROAMERICA } from '@/config/app'

export default {
  components: {
    // BSV
    BRow,
    BCol,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
      submitting: false,
    }
  },
  computed: {
    isCentroAmerica() {
      return IS_CENTROAMERICA
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    crispChat() {
      // eslint-disable-next-line no-undef
      $crisp.push(['do', 'chat:open'])
      return Promise.resolve()
    },
    login() {
      const self = this
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          self.submitting = true
          this.$store.dispatch('account/login', { email: this.userEmail, password: this.password }).then(
            () => {
              self.submitting = false
              return Promise.resolve()
            },
            () => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notificación',
                  icon: 'AlertCircleIcon',
                  text: 'Los datos ingresados no coinciden con nuestros registros',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-center',
                timeout: 10000,
              })
              self.submitting = false
              return Promise.resolve()
            },
          )
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.auth-back{
  background-image: url('~@/assets/images/promoshop-img-login.jpg')!important;
  background-position: center;
  height: 100vh!important;
  width: 100%!important;
}
.btn-gradient-promo{
  background: rgb(246,162,27);
  background: -moz-linear-gradient(90deg, rgba(246,162,27,1) 0%, rgba(236,44,57,1) 50%, rgba(74,34,104,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(246,162,27,1) 0%, rgba(236,44,57,1) 50%, rgba(74,34,104,1) 100%);
  background: linear-gradient(90deg, rgba(246,162,27,1) 0%, rgba(236,44,57,1) 50%, rgba(74,34,104,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6a21b",endColorstr="#4a2268",GradientType=1);
  border: none;
}
.auth-v1 {
  .auth-inner {
    &:before {
      background-image: none!important;
      }
    }
  }

</style>
